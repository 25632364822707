<template>
  <TTView>
    <VRow>
      <VCol>
        UIKIT
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          :items="tableItems"
          :headers="headers"
        >
          <template #item.name="{value, item}">
            <div class="d-flex align-center">
              <VAvatar>
                <VImg
                  max-width="36"
                  height="36"
                  :src="require(`@/assets/skills/${item.img}.png`)"
                />
              </VAvatar>
              <div class="ml-3">
                <div class="cell-primary-text">
                  {{ value }}
                </div>
                <div class="cell-secondary-text">
                  {{ item.type }}
                </div>
              </div>
            </div>
          </template>

          <template #item.grade="{item}">
            <div class="cell-primary-text">
              {{ getStatus(item).text }}
              <VIcon
                size="16"
                :color="getStatus(item).color"
                class="ml-3"
              >
                {{ getStatus(item).icon }}
              </VIcon>
            </div>
            <div class="cell-secondary-text">
              {{ item.exam.completed_at.slice(0,9).replaceAll('-','.') }}
            </div>
          </template>

          <template #item.level="{value}">
            <div class="cell-primary-text">
              {{ getReqLevel(value) }}
            </div>
            <div class="cell-secondary-text">
              Уровень
            </div>
          </template>

          <template #item.progress="{item}">
            <VContainer
              fluid
              class="pa-0"
            >
              <VRow>
                <VCol
                  cols="6"
                >
                  <div class="cell-primary-text pb-2">
                    <VIcon
                      size="16"
                      :color="item.progress.color"
                      class="mr-1"
                    >
                      {{ item.progress.icon }}
                    </VIcon>
                    {{ item.progress.value }} %
                  </div>
                  <div class="cell-secondary-text">
                    <VProgressLinear
                      :value="item.progress.value"
                      :color="item.progress.color"
                    />
                  </div>
                </VCol>
                <VCol
                  cols="6"
                >
                  <TTBtn
                    @click="log(item)"
                  >
                    Пройти тест >
                  </TTBtn>
                </VCol>
              </VRow>
            </VContainer>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          :items="tableItems"
          :headers="headers"
          :expanded.sync="expanded"
          item-key="name"
          show-expand
        >
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length+1">
              More info about {{ item.name }}
            </td>
          </template>
          <template #item.name="{value, item}">
            <div class="d-flex align-center">
              <VAvatar>
                <VImg
                  max-width="36"
                  height="36"
                  :src="require(`@/assets/skills/${item.img}.png`)"
                />
              </VAvatar>
              <div class="ml-3">
                <div class="cell-primary-text">
                  {{ value }}
                </div>
                <div class="cell-secondary-text">
                  {{ item.type }}
                </div>
              </div>
            </div>
          </template>

          <template #item.grade="{item}">
            <div class="cell-primary-text">
              {{ getStatus(item).text }}
              <VIcon
                size="16"
                :color="getStatus(item).color"
                class="ml-3"
              >
                {{ getStatus(item).icon }}
              </VIcon>
            </div>
            <div class="cell-secondary-text">
              {{ item.exam.completed_at.slice(0,9).replaceAll('-','.') }}
            </div>
          </template>

          <template #item.level="{value}">
            <div class="cell-primary-text">
              {{ getReqLevel(value) }}
            </div>
            <div class="cell-secondary-text">
              Уровень
            </div>
          </template>

          <template #item.progress="{item}">
            <VContainer
              fluid
              class="pa-0"
            >
              <VRow>
                <VCol
                  cols="6"
                >
                  <div class="cell-primary-text pb-2">
                    <VIcon
                      size="16"
                      :color="item.progress.color"
                      class="mr-1"
                    >
                      {{ item.progress.icon }}
                    </VIcon>
                    {{ item.progress.value }} %
                  </div>
                  <div class="cell-secondary-text">
                    <VProgressLinear
                      :value="item.progress.value"
                      :color="item.progress.color"
                    />
                  </div>
                </VCol>
                <VCol
                  cols="6"
                >
                  <TTBtn
                    @click="log(item)"
                  >
                    Пройти тест >
                  </TTBtn>
                </VCol>
              </VRow>
            </VContainer>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>

export default {
  name: 'UIKit',

  data() {
    return {
      expanded: [],
      headers: [
        {
          text: 'Навык',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Статус',
          value: 'grade',
          sortable: true,
        },
        {
          text: 'Требуемый уровень',
          value: 'level',
          sortable: true,
        },
        {
          text: 'Прогресс обучения',
          value: 'progress',
          sortable: true,
        },
      ],
      tableItems: [
        {
          id: 0,
          name: 'MS Excel',
          level: 2,
          grade: 3,
          done: false,
          type: 'Работа с данными',
          img: 'excel',
          progress: { value: 25, icon: 'fa-chevron-circle-right', color: 'info' },
          exam: {
            status: 'active',
            grade: 0,
            completed_at: '2020-09-29T13:55:13.591Z',
          },
          test_type: 'string',
        },
        {
          id: 1,
          name: 'MS Power BI',
          level: 1,
          grade: 0,
          done: true,
          type: 'Работа с данными',
          img: 'bi',
          progress: { value: 25, icon: 'fa-chevron-circle-right', color: 'info' },
          exam: {
            status: 'finished',
            grade: 1,
            completed_at: '2020-09-29T13:55:13.591Z',
          },
          test_type: 'string',
        },
        {
          id: 2,
          name: 'Кибербезопасность',
          level: 0,
          grade: 3,
          done: true,
          type: 'Работа с данными',
          img: 'cyber',
          progress: { value: 100, icon: 'fa-check-circle', color: 'success' },
          exam: {
            status: 'finished',
            grade: 0,
            completed_at: '2020-09-29T13:55:13.591Z',
          },
          test_type: 'string',
        },
      ],
    };
  },
  computed: {
    foo() { return ''; },
  },
  methods: {
    log(v) {
      console.warn(v);
    },
    getReqLevel(level) {
      const levels = ['Базовы', 'Средний', 'Продвинутый'];
      return levels[level];
    },
    getStatus(item) {
      const grades = [
        {
          text: 'Не оценен',
          color: 'error',
          icon: 'fa-times-circle',
        },
        {
          text: 'Не подтвержден',
          color: 'warning',
          icon: 'fa-clock',
        },
        {
          text: 'Подтвержден',
          color: 'success',
          icon: 'fa-check-circle',
        },
      ];
      if (!item.done) return grades[0];
      return item.grade < item.level ? grades[2] : grades[1];
    },
  },
};
</script>

<style lang="scss">

.cell-primary-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.61px;
  color: #0B1218;
}

.cell-secondary-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.61px;
  color: #8F9295;
}

</style>
