var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_vm._v(" UIKIT ")])],1),_c('VRow',[_c('VCol',[_c('TTDataTable',{attrs:{"items":_vm.tableItems,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('VAvatar',[_c('VImg',{attrs:{"max-width":"36","height":"36","src":require(("@/assets/skills/" + (item.img) + ".png"))}})],1),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"cell-primary-text"},[_vm._v(" "+_vm._s(value)+" ")]),_c('div',{staticClass:"cell-secondary-text"},[_vm._v(" "+_vm._s(item.type)+" ")])])],1)]}},{key:"item.grade",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-primary-text"},[_vm._v(" "+_vm._s(_vm.getStatus(item).text)+" "),_c('VIcon',{staticClass:"ml-3",attrs:{"size":"16","color":_vm.getStatus(item).color}},[_vm._v(" "+_vm._s(_vm.getStatus(item).icon)+" ")])],1),_c('div',{staticClass:"cell-secondary-text"},[_vm._v(" "+_vm._s(item.exam.completed_at.slice(0,9).replaceAll('-','.'))+" ")])]}},{key:"item.level",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"cell-primary-text"},[_vm._v(" "+_vm._s(_vm.getReqLevel(value))+" ")]),_c('div',{staticClass:"cell-secondary-text"},[_vm._v(" Уровень ")])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('VContainer',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('div',{staticClass:"cell-primary-text pb-2"},[_c('VIcon',{staticClass:"mr-1",attrs:{"size":"16","color":item.progress.color}},[_vm._v(" "+_vm._s(item.progress.icon)+" ")]),_vm._v(" "+_vm._s(item.progress.value)+" % ")],1),_c('div',{staticClass:"cell-secondary-text"},[_c('VProgressLinear',{attrs:{"value":item.progress.value,"color":item.progress.color}})],1)]),_c('VCol',{attrs:{"cols":"6"}},[_c('TTBtn',{on:{"click":function($event){return _vm.log(item)}}},[_vm._v(" Пройти тест > ")])],1)],1)],1)]}}])})],1)],1),_c('VRow',[_c('VCol',[_c('TTDataTable',{attrs:{"items":_vm.tableItems,"headers":_vm.headers,"expanded":_vm.expanded,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length+1}},[_vm._v(" More info about "+_vm._s(item.name)+" ")])]}},{key:"item.name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('VAvatar',[_c('VImg',{attrs:{"max-width":"36","height":"36","src":require(("@/assets/skills/" + (item.img) + ".png"))}})],1),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"cell-primary-text"},[_vm._v(" "+_vm._s(value)+" ")]),_c('div',{staticClass:"cell-secondary-text"},[_vm._v(" "+_vm._s(item.type)+" ")])])],1)]}},{key:"item.grade",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-primary-text"},[_vm._v(" "+_vm._s(_vm.getStatus(item).text)+" "),_c('VIcon',{staticClass:"ml-3",attrs:{"size":"16","color":_vm.getStatus(item).color}},[_vm._v(" "+_vm._s(_vm.getStatus(item).icon)+" ")])],1),_c('div',{staticClass:"cell-secondary-text"},[_vm._v(" "+_vm._s(item.exam.completed_at.slice(0,9).replaceAll('-','.'))+" ")])]}},{key:"item.level",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"cell-primary-text"},[_vm._v(" "+_vm._s(_vm.getReqLevel(value))+" ")]),_c('div',{staticClass:"cell-secondary-text"},[_vm._v(" Уровень ")])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('VContainer',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('div',{staticClass:"cell-primary-text pb-2"},[_c('VIcon',{staticClass:"mr-1",attrs:{"size":"16","color":item.progress.color}},[_vm._v(" "+_vm._s(item.progress.icon)+" ")]),_vm._v(" "+_vm._s(item.progress.value)+" % ")],1),_c('div',{staticClass:"cell-secondary-text"},[_c('VProgressLinear',{attrs:{"value":item.progress.value,"color":item.progress.color}})],1)]),_c('VCol',{attrs:{"cols":"6"}},[_c('TTBtn',{on:{"click":function($event){return _vm.log(item)}}},[_vm._v(" Пройти тест > ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }